<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">

    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <div class="me-0">
        <a
          href="#"
          class="btn btn-sm btn-light btn-active-light-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          {{
            activeTenantName
          }} 
          <span class="svg-icon svg-icon-5 m-0">
            <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
          </span>
        </a>
        <!--begin::Menu-->
        <div
          class="
            menu
            menu-sub
            menu-sub-dropdown
            menu-column
            menu-rounded
            menu-gray-600
            menu-state-bg-light-primary
            fw-bold
            fs-7
            w-200px
            py-4
          "
          data-kt-menu="true"
        >
          <!--begin::Menu item-->
          <div
            class="menu-item px-3"
            v-for="(tenantEstate, index) in tenantEstates"
            :key="index"
          >
          <a class="menu-link px-3" @click="activeTenantName !== tenantEstate.name && changeTenant(tenantEstate)">
        {{ tenantEstate.name }}
      </a>
          </div>
          <!--end::Menu item-->
        </div>
      </div>
    </div>


    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
      id="kt_header_user_menu_toggle"
    >
      <!--begin::Menu-->
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <img src="/media/avatars/blank.png" />
      </div>
      <KTUserMenu
        :email="email"
        :name="name"
        :surName="surName"
        :phone="phone"
        :countryCode="countryCode"
      ></KTUserMenu>
      <!--end::Menu-->
    </div>
    <!--end::User -->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import KTUserMenu from "@/presentation/layout/header/partials/UserMenu.vue";
import { COMPONENT_NAME } from "@/presentation/constants/Components";
import { useStore } from "vuex";
import { ChangeTenantEstateModel } from "@/domain/auth/changeTenantEstate/model/ChangeTenantEstateModel";
import { TenantEstateModel } from "@/domain/user/tenantEstate/model/TenantEstateModel";
import { useRouter } from "vue-router";

export default defineComponent({
  name: COMPONENT_NAME.TOPBAR,
  props: {
    activeTenantName: String,
    tenantEstates: Object,
    email: String,
    name: String,
    surName: String,
    phone: String,
    countryCode: String,
  },
  components: {
    KTUserMenu,
  },
  setup() {
    const store = useStore();
    const userController = store.state.ControllersModule.userController;
    const router = useRouter();
    const changedTenant = ref<number>(0);

    const changeTenant = (tenantEstate: TenantEstateModel) => {
      const changeTenantEstateModel: ChangeTenantEstateModel = {
        tenantEstateId: Number(tenantEstate.id),
      };

      userController
        .changeTenantEstate(changeTenantEstateModel)
        .then((response) => {
          if(response.isSuccess){
            window.location.href="/dashboard"
            /*
                        location.reload();
            router.push({ name: "dashboard" });
            */

            /*
            changedTenant.value += 1;
              store.dispatch("changeTenant", changedTenant.value);

            router.push({ name: "dashboard" });
*/
          }
        });
    };

    return {
      changeTenant
    };
  },
});
</script>
