export interface MenuItem {
  pages: {
    sectionTitle?: string;
    heading?: string;
    route: string;
    svgIcon: string;
    fontIcon: string;
    permission?: string;
    shortCode?: string;
    sub?: {
      heading?: string;
      route: string;
      sectionTitle?: string;
      sub?: {
        heading: string;
        route: string;
        sectionTitle?: string;
      }[];
    }[];
  }[];
  sectionTitle?: string;
  heading?: string;
  route?: string;
  permission?: string;
  active?: boolean;
  shortCode?: string;
  sub?: {
    heading?: string;
    route: string;
    sectionTitle?: string;
    sub?: {
      heading: string;
      route: string;
      sectionTitle?: string;
    }[];
  }[];
}

const AIMenuConfig: MenuItem[] = [
  {
    pages: [
      {
        heading: "dashboard.title",
        route: "/dashboard",
        svgIcon: "/media/icons/duotune/general/gen001.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "asideMenu.modules.activeModules",
    route: "/modules",
    active: true,
    pages: [
      {
        heading: "asideMenu.modules.hotelManagement.title",
        route: "/hotelManagement/",
        permission: "TENANT_USER",
        shortCode: "OGZA_HOTEL_ASSISTANT_AI",
        svgIcon: "/media/icons/duotune/general/gen003.svg",
        fontIcon: "bi-person",
      },
      {
        sectionTitle: "asideMenu.modules.hotelManagement.title",
        route: "/hotelManagement/",
        permission: "TENANT_ADMIN",
        shortCode: "OGZA_HOTEL_ASSISTANT_AI",
        svgIcon: "/media/icons/duotune/general/gen003.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "asideMenu.modules.hotelManagement.assistant",
            route: "/hotelManagement/",
          },
          {
            heading: "asideMenu.modules.hotelManagement.history",
            route: "/hotelManagement/staffList",
          },
        ],
      },

/*
      {
        sectionTitle: "asideMenu.modules.pollster.title",
        route: "/pollsterCreate",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "asideMenu.modules.pollster.create",
            route: "/pollsterCreate",
          },
          {
            heading: "asideMenu.modules.pollster.emailAddresses",
            route: "/pollsterEmails",
          },
        ],
      },

      {
        heading: "asideMenu.modules.interview.create",
        route: "/interviewCreate",
        svgIcon: "/media/icons/duotune/communication/com007.svg",
        fontIcon: "bi-person",
      },

      */
    ],
  },
  {
    heading: "asideMenu.modules.header",
    route: "/modules",
    permission: "TENANT_ADMIN",
    pages: [
      {
        heading: "asideMenu.admin.modules",
        route: "/moduleList",
        permission: "TENANT_ADMIN",
        // permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-person",
      },
    ],
  },
  /*
  {
    heading: "asideMenu.user",
    route: "/system",
    permission: "MODULES",
    pages: [
      {
        heading: "asideMenu.modules.pollster.polls",
        route: "/startPoll",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.modules.interview.title",
        route: "/startInterview",
        svgIcon: "/media/icons/duotune/communication/com007.svg",
        fontIcon: "bi-person",
      },
    ],
  },
  */
  {
    heading: "asideMenu.admin.header",
    route: "/system",
    permission: "TENANT_ADMIN",
    pages: [
      {
        heading: "asideMenu.admin.accountTypes",
        route: "/accountTypes",
        permission: "TENANT_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com008.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.admin.accountStatus",
        route: "/accountStatus",
        // permission: "TENANT_ADMIN",
        permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.admin.staff",
        route: "/staffList",
        permission: "TENANT_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com005.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.admin.addStaff",
        route: "/addStaff",
        permission: "TENANT_ADMIN",
        svgIcon: "/media/icons/duotune/general/gen041.svg",
        fontIcon: "bi-person",
      },
    ],
  },
  {
    heading: "asideMenu.ogza.header",
    route: "/system",
    // permission: "TENANT_ADMIN",
    permission: "SUPER_ADMIN",
    pages: [
      {
        heading: "asideMenu.admin.modules",
        route: "/allModule",
        //   permission: "TENANT_ADMIN",
        permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.ogza.companies",
        route: "/companies",
        //   permission: "TENANT_ADMIN",
        permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com008.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.ogza.createCompany",
        route: "/createCompany",
        // permission: "TENANT_ADMIN",
        permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com008.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.ogza.chatgptSettings",
        route: "/chatGptSettings",
        //  permission: "TENANT_ADMIN",
        permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
    ],
  },
];

export default AIMenuConfig;
