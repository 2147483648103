
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/presentation/helper/LayoutConfig";
import { COMPONENT_NAME } from "@/presentation/constants/Components";

export default defineComponent({
  name: COMPONENT_NAME.KT_FOOTER,
  setup() {
    return {
      footerWidthFluid,
    };
  },
});
