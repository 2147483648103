
import { defineComponent, computed, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/presentation/layout/aside/Aside.vue";
import KTHeader from "@/presentation/layout/header/Header.vue";
import KTFooter from "@/presentation/layout/footer/Footer.vue";
import HtmlClass from "@/presentation/config/LayoutService";
import KTToolbar from "@/presentation/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/presentation/layout/extras/ScrollTop.vue";
import { Actions } from "../store/enums/StoreEnums";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/presentation/assets/ts/components/index";
import { removeModalBackdrop } from "../helper/Dom";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/presentation/helper/LayoutConfig";
import { AboutUserModel } from "@/domain/user/aboutUser/model/AboutUserModel";
import SwalNotification from "../plugins/SwalNotification";
import { ROUTE_PAGES } from "@/domain/constants/RoutePages";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";import { useI18n } from "vue-i18n";
import { TenantEstateModel } from "@/domain/user/tenantEstate/model/TenantEstateModel";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTToolbar,
    KTScrollTop,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const swalNotification = new SwalNotification();

    const activeTenantEstate = ref({ activeTenantEstate: [] });
    const activeTenantName = ref({ tenantName: "" });
    const tenants = ref({ tenantEstates: [] });
    const userPhotoUrl = ref({ url: "/media/avatars/blank.png" });

    const user = ref<AboutUserModel>({
      email: "",
      name: "",
      surname: "",
    });

    const tenantEstates = ref<TenantEstateModel[]>([]);
      const activeTenant = ref<TenantEstateModel>({
      name: "",
      shortCode: "",
      tenantRole: undefined,
    });
    const userController = store.state.ControllersModule.userController;

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const getUserInfo = () => {
      userController
        .aboutUser()
        .then((response) => {
          if (response.isSuccess) {
        //    console.log("response", response.getValue());
            Object.assign(user.value, response.getValue());
        //    console.log("tenantEstates", response.getValue().tenantEstates)
        //    console.log("activeTenantEstate", response.getValue().activeTenantEstate)

            activeTenantName.value.tenantName = response
              .getValue()
              .activeTenantEstate.name;


            tenantEstates.value = response.getValue().tenantEstates
            // activeTenant.value = response.getValue().activeTenantEstate

            /*
            Object.assign(
              tenants.value.tenantEstates,
              response.getValue().tenantEstates
            );

            Object.assign(
              activeTenantEstate.value.activeTenantEstate,
              response.getValue().activeTenantEstate
            );
            */


            if (response.getValue().profilePhotoUrl) {
              userPhotoUrl.value.url = response.getValue().profilePhotoUrl;
            }
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: ROUTE_PAGES.SIGN_IN });
      } else {
        getUserInfo();
        DrawerComponent.bootstrap();
        ScrollComponent.bootstrap();
        DrawerComponent.updateAll();
        ScrollComponent.updateAll();
      }

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    watch(
      () => localStorage.getItem("jwt"),
      (newValue, oldValue) => {
        if (newValue) getUserInfo();
      }
    );

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({ name: ROUTE_PAGES.SIGN_IN });
        }

        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      userPhotoUrl,
      user,
      tenants,
      activeTenantEstate,
      activeTenantName,
      tenantEstates,
      activeTenant
    };
  },
});
